<template>
  <div class="relative">
    <base-input
      inset
      :disabled="disabled"
      with-label
      label="Kode Gudang"
      type="text"
      placeholder="GDSC1"
      v-model="warehouse.searchCode"
      @change="onSearchWarehouse"
    />
    <button
      v-if="!disabled"
      type="button"
      class="absolute right-4 top-6 cursor-pointer text-gray-400"
      @click="onOpenWarehouseModal"
    >
      <Icon class="h-5 w-5" icon="heroicons:magnifying-glass-circle-20-solid" />
    </button>

    <warehouse-table-modal
      :visible="visibleWarehouseModal"
      @close="visibleWarehouseModal = false"
      @click-warehouse="onChangeWarehouse"
    />

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import WarehouseTableModal from './warehouse-table-modal.vue';

export default {
  components: { WarehouseTableModal },
  mixins: [requestMixin],
  props: {
    value: Object,
    disabled: Boolean,
  },
  emits: ['input', 'change'],
  data() {
    return {
      loading: false,
      visibleWarehouseModal: false,
    };
  },
  computed: {
    warehouse: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onOpenWarehouseModal() {
      this.visibleWarehouseModal = true;
    },
    async onChangeWarehouse(warehouse) {
      this.warehouse.searchCode = warehouse.attributes.code;

      await this.onSearchWarehouse();

      this.visibleWarehouseModal = false;
    },
    async onSearchWarehouse() {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/warehouses', {
        params: {
          'filter[warehouse_type]': 'pusat,kantor_pelayanan',
          'filter[code]': this.warehouse.searchCode.split(' ')[0],
          'fields[warehouses]': 'code,name,area',
          'fields[areas]': 'code',
          include: 'area',
        },
      });

      if (err || !res.data.length) {
        this.warehouse.searchCode = this.warehouse.originalCode;
      } else {
        const warehouse = res.data[0];

        this.warehouse.id = warehouse.id;
        this.warehouse.code = warehouse.attributes.code;
        this.warehouse.name = warehouse.attributes.name;

        const area = this.getSingleIncluded(
          res,
          warehouse.relationships.area.data.id
        );

        this.warehouse.area.id = area.id;
        this.warehouse.area.code = area.attributes.code;

        this.warehouse.searchCode = `${warehouse.attributes.code} (${area.attributes.code})`;
        this.warehouse.originalCode = `${warehouse.attributes.code} (${area.attributes.code})`;

        this.$emit('change');
      }

      this.loading = false;
    },
  },
};
</script>
